import React, { Component } from "react"
import {
  Box,
  Container,
  Paper,
  Typography,
  makeStyles,
  useTheme
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"

const appStyle = (theme) => ({
  root: {
    display: "flex",
    width: "100%",
    height: `calc(100vh - 130px)`
  }
})

class ClientDocs extends Component {
  render() {
    const { classes } = this.props
    return (
      <Paper className={classes.root}>
        <iframe
          width={"100%"}
          height={"100%"}
          src={`${process.env.REACT_APP_COVERAGE_DOCS_URL}docs/`}
        ></iframe>
      </Paper>
    )
  }
}

export default withStyles(appStyle)(ClientDocs)
